@font-face {
  font-family: nobel;
  src: url(assets/font/Nobel-Regular.ttf);
}
@font-face {
  font-family: nobelLight;
  src: url(assets/font/Nobel-Light.ttf);
}
@font-face {
  font-family: nobelBook;
  src: url(assets/font/Nobel-Book.ttf);
}
@font-face {
  font-family: nobelBold;
  src: url(assets/font/Nobel-Bold.ttf);
}


* {
    font-family: nobel;
    --tw-ring-color:rgb(167,143,110)!important;
  }
body{
  background-color: black;
}

  .nofocus{
    outline: unset!important;
    outline-offset: unset!important;
    --tw-ring-inset: unset!important;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color:unset!important;
    --tw-ring-color: unset!important;
    --tw-ring-offset-shadow: unset!important;
    --tw-ring-shadow: unset!important;
    box-shadow: unset!important;
    border-color: unset!important;
  }
  .cursor-help {cursor: help;}
  .cursor-pointer {cursor: pointer;}


  .h-30-px {
    height: 30px;
  }
  .h-300-px {
    height: 300px;
  }
  .h-325-px {
    height: 325px;
  }
  .h-350-px {
    height: 350px;
  }
  .h-375-px {
    height: 375px;
  }
  .h-400-px {
    height: 400px;
  }

.min-h-screen-50{
  min-height: 50vh;
}

.min-h-screen-60{
  min-height: 60vh;
}

.max-h-400 {
  max-height: 400px;
}

.max-w-25-px {
  max-width: 25px;
}
.max-w-50-px {
  max-width: 50px;
}

.min-w-25-px {
  min-width: 25px;
}

.min-w-50-px {
  min-width: 50px;
}

.bg-table-red{
  background-color: rgb(255,0,0,0.2);
}
.bg-table-orange{
  background-color: rgb(255,188,0,0.2);
}
.bg-table-yellow{
  background-color: rgba(251,255,0,0.2);
}
.bg-table-green{
  background-color: rgba(0, 255, 34, 0.2);
}

.bg-table-purple{
  background-color: rgb(245 4 226 / 20%);;
}
.bg-table-blue{
  background-color: rgb(34 204 243 / 20%);;
}
.bg-table-gray{
  background-color: rgb(90 90 90 / 20%);;
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgba(60, 61, 64, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 180, 180, var(--tw-bg-opacity));
}

.bg-gold{
  --tw-text-opacity: 1;
  background-color: rgba(190, 157, 115, var(--tw-text-opacity));
}

.bg-tooltip{
  --tw-text-opacity: 1;
  background-color: rgba(167,143,110, var(--tw-text-opacity))!important;
  background: rgba(167,143,110, var(--tw-text-opacity))!important;
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 0, 0, var(--tw-bg-opacity));
}

.place-top::after {
  border-top-color: rgba(167,143,110, var(--tw-text-opacity))!important;
  background-color: inherit!important;
}

.place-left::after {
  border-left-color: rgba(167,143,110, var(--tw-text-opacity))!important;
  background-color: inherit!important;
}

.place-right::after {
  border-right-color: rgba(167,143,110, var(--tw-text-opacity))!important;
  background-color: inherit!important;
}

.place-bottom::after {
  border-bottom-color: rgba(167,143,110, var(--tw-text-opacity))!important;
  background-color: inherit!important;
}

.bg-tooltip-red{
  --tw-text-opacity: 1;
  background-color: rgba(255,0,0, var(--tw-text-opacity))!important;
  background: rgba(255,0,0, var(--tw-text-opacity))!important;
}

.bg-tooltip-red.place-top::after{
  border-top-color: rgba(255,0,0, var(--tw-text-opacity))!important;
}

.bg-tooltip-red.place-left::after {
  border-left-color: rgba(255,0,0, var(--tw-text-opacity))!important;
}

.bg-tooltip-red.place-right::after {
  border-right-color: rgba(255,0,0, var(--tw-text-opacity))!important;
}

.bg-tooltip-red.place-bottom::after {
  border-bottom-color: rgba(255,0,0, var(--tw-text-opacity))!important;
}

.disabled {
    --tw-bg-opacity: 1;
    background-color: rgba(227, 227, 227, var(--tw-bg-opacity))!Important;
    opacity: 0.5;
}

.bg-header {
    background-image: linear-gradient(to right, #121212, rgba(60, 61, 64, var(--tw-bg-opacity)));
}

.bg-login {
    background-image: linear-gradient(#121212, #4a4a4a);
}

.bg-search {
  background-color: rgba(60, 61, 64, var(--tw-bg-opacity));
  border:1px solid white;
}
  
  
.bg-login-panel {
  background-image: linear-gradient(to bottom left,#080808,#1c1c1d);
}


.w-200-px {
  width: 200px;
}
.w-10 {
  width: 10%;
} 
.w-90 {
  width: 90%;
}
.w-05\/12 {
  width: 4,1666667%;
}
.w-1\/12 {
  width: 8.3333334%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333334%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-11\/12 {
  width: 91.6666666%;
}

  .xl\:w-5\/12 {
      width: 41.666667%;
    }


.button{
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  font: 400 13.3333px Arial;
  border-image: initial;
}
    .button-login{
      background-color:#242425;
      border: 2px solid white;
    }
    .button-login-noborder{
      background-color:#242425;
    }
  .button-login-white{
    background-color:white;
    border: 2px solid #242425;
    color:#242425;
  }
  .button-gold{
    background-color: rgba(190, 157, 115, var(--tw-text-opacity));
    color:#fafafa;
  }
  .button-transparant{
    border: 2px solid white;
  }

  .py-0 {
    padding-top: 0rem!important;
    padding-bottom: 0rem!important;
  }

  .px-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .pl-0{
    padding-left: 0rem;
  } 
  .pl-6{
    padding-left: 1.5rem;
  } 
  .pt-4{
    padding-top: 1rem;
  } 
  .pt-40{
    padding-top: 10rem;
  } 
  .pr-6{
    padding-right: 1.5rem;
  }
  .pr-7{
    padding-right: 1.75rem;
  }
  .pr-8{
    padding-right: 2rem;
  }
  .pr-9{
    padding-right: 2.25rem;
  }
  .pr-10{
    padding-right: 2.5rem;
  }
  
  .my-auto {
    margin-top: auto;
    margin-bottom:auto;
  }
  .mt-auto {
    margin-top: auto;
  }
  .mb-auto {
    margin-bottom: auto;
  }
  .mb-2px {
    margin-bottom: 2px;
  }

  .mr-5 {
    margin-right: 1.25rem;
  }


  .ml-2 {
    margin-left: 0.5rem;
  }

  .ml-4 {
    margin-left: 1rem;
  }
  
  .ml-5 {
    margin-left: 1.25rem;
  }

  .-ml-3 {
    margin-left: -0.725rem;
  }

  .-ml-5 {
    margin-left: -1.25rem;
  }
  
  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .placeholder-white::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }

  .text-2xs {
    font-size: 0.6rem;
    line-height: 0.75rem;
  }

  .text-3xs {
    font-size: 0.5rem;
    line-height: 0.75rem;
  }

  .text-red{
    --tw-text-opacity: 1;
    color: rgba(255, 0, 0, var(--tw-text-opacity));
  }
  .text-gray{
    --tw-text-opacity: 1;
    color: rgba(106, 112, 116, var(--tw-text-opacity));
  }
  .text-gold{
    --tw-text-opacity: 1;
    color: rgba(190, 157, 115, var(--tw-text-opacity));
  }
  
  .hover\:text-gold:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 157, 115, var(--tw-text-opacity));
  }
  
.placeholder-gray::-webkit-input-placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(106, 112, 116, var(--tw-text-opacity));
  }
  
  .inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.opacity-5 {
  opacity: 0.05;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}
.opacity-100 {
  opacity: 1;
}


.max-w-sm {
  max-width: 24rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-6 {
  padding: 1.5rem;
}
.pb-8{
  padding-bottom: 2rem;
}

.w-10\/12 {
  width: 83.333333%;
}


@media (min-width: 1280px) {
  .xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xl\:w-1\/12 {
    width: 8.333333333333333%;
  }
  .xl\:w-2\/12 {
    width: 16.66666666666667%!important;
  }
  .xl\:w-1\/5 {
    width: 20%!important;
  }
  .xl\:w-9\/12 {
    width: 75%;
  }
  
  
  .customerinfo-field:nth-child(even){
    padding-left:1rem;
  }
  .customerinfo-field:nth-child(odd){
    padding-right:1rem;
  }

}

.tracking-wide01 {
  letter-spacing: 0.1em;
}

/*notifbar*/
.notificationArea-login{
  position: fixed;
  bottom: 20px;
  z-index:1;
}
.notificationArea{
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index:1;
}
.notifBar {
  z-index: 999999999;
  font-size: 16px;
  color: #fff;
  padding: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top:10px;
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
}

.notifBar-content {
  display: inline;
}
.notifBar-right {
  display: flex;
  align-items: center;
}
/*notifbar*/

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}
/*
tr th {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
tr:nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
tr:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}*/

.z-30 {
  z-index: 30;
}
.z-100 {
  z-index: 100;
}

.rounded-tl {
  border-top-left-radius: 0.25rem;
}
.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-tr {
  border-top-right-radius: 0.25rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-b {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.rounded-b-lg {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-br {
  border-bottom-right-radius: 0.25rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.circle{
  border-radius: 50%;
}

li.tabs-header:first-child{
  border-left:unset!important;
  border-top-left-radius: 0.5rem;
}

li.tabs-header:not(:first-child),li.tabs-header:not(:last-child) {
  border-left:1px solid;
  border-right:1px solid;
  border-color:rgb(255 255 255)
}
li.tabs-header:last-child{
  border-top-right-radius: 0.5rem;
  border-right:unset!important;
}

.errorNotif{
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.successNotif{
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.sticky-top{
  position: sticky;
  top:0;
}


.daterangepicker-control-section {
  max-width: 246px;
  margin: 30px auto;
  padding-top: 50px;
}


.DayPicker-Months{
  flex-wrap:unset!important
}


.Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Range .DayPicker-Day {
  border-radius: 0 !important;
}


.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.top-50{
  top:50%
}

.-top-75-px {
  top: -75px;
}

.-top-10-px {
  top: -10px;
}

.-top-5-px {
  top: -5px;
}

.right-1 {
  right: 1rem;
}
.right-2 {
  right: 2rem;
}

.right-10-px {
  right: 10px;
}

.justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.align-self-start{
  align-self: flex-start;
}

.grid{
  display: grid;
}

.grid-2{
  display: grid;
  grid-template-columns: 88% 12%;
}

@media (min-width: 1024px) {
  /*lg*/
  
  .lg\:w-15\/12 {
    width: 12.5%;
  }
  .lg\:w-2\/12 {
    width: 16.666667%;
  }
  .lg\:w-5\/12 {
    width: 41.666667%;
  }
  .lg\:w-105\/12 {
    width: 87.5%;
  }
  .lg\:w-11\/12 {
    width: 91.6666666%;
  }
  
  .lg\:pr-4 {
    padding-right: 1rem!important;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
  .md\:w-6\/12 {
    width: 50%;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
  .md\:w-6\/12 {
    width: 40%!Important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /*md*/
  .md\:w-3\/12 {
    width: 25%;
  }
  .md\:w-4\/10 {
    width: 25%;
  }
  .md\:-ml-3 {
    margin-left: -0.75rem;
  }
  .md\:-ml-5 {
    margin-left: -1.25rem;
  }
  .md\:mb-0 {
    margin-bottom: 0!important;
  }
  .md\:pr-0 {
    padding-right: 0!important;
  }

}

@media (max-width: 768px) {
  /*sm*/
  .sm\:w-full {
    width: 100%;
  }
  .sm\:mb-0 {
    margin-bottom: 0!important;
  }
  .sm\:pr-0 {
    padding-right: 0!important;
  }
}


.resize-none{
  resize:none
}

/*login bg */


.animated-background{
  background: linear-gradient(90deg, rgba(13,95,94,1) 0%, rgba(2,37,57,1) 35%, rgba(25,10,61,1) 69%, rgba(0,40,48,1) 100%);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.animated-background2{
  background: linear-gradient(45deg, #121212, hsl(0, 0%, 22%), #919191, #ffffff);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.animated-background3{
  background: linear-gradient(90deg, rgba(19,24,28,1) 0%, rgba(83,82,80,1) 35%, rgba(11,18,31,1) 69%, rgba(0,19,23,1) 100%);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.animated-background4{
  background: linear-gradient(90deg, rgba(0,19,23,1) 0%, rgba(19,24,28,1) 29%, rgba(83,82,80,1) 72%, rgba(11,18,31,1) 100%);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.animated-background5{
  background: linear-gradient(90deg, rgba(11,18,31,1) 0%, rgba(0,19,23,1) 32%, rgba(19,24,28,1) 69%, rgba(83,82,80,1) 100%);
  background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/*login bg */

.border-lightBlue-300 {
  --tw-text-opacity: 1;
  border-color: rgba(125, 211, 252, var(--tw-text-opacity));
}

.__react_component_tooltip.show{
  opacity:1!important;
  font-weight:100!important ;
  font-size:15px;
} 


.legend .legend-color{
  width:30px;
  height:10px;
}

.msg-wrapper {
  white-space: pre-wrap;
}

.multi-select {
  max-width: 200px;
  width: 200px;
}

.multi-select, .multi-select .dropdown-container, .multi-select .dropdown-content {
  background-color:white !important;
}
.multi-select .dropdown-container{
  border: 1px solid #ccc;
  border-radius: 4px;
}
.multi-select .dropdown-heading{
  padding: 10px;
  height: 38px;
}

.go2139093995 {
  --rmsc-main: rgba(190, 157, 115, var(--tw-text-opacity));
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 38px;
}
._1WnkD, .funnel-pipeline-chart, .funnel-chart {
  clip-path: polygon(50% 100%, 0 0, 100% 0)!important;
}
._1WnkD ._1JWHC, ._1WnkD ._1JWHC ._3tM6f, .funnel-pipeline-chart .funnel-pipeline-chart-row{
  font-size: 14px!important;
}
/*._3tM6f{
  padding: 15px 0 20px 0;
}*/
.columnFunnel:hover{
  background-color: #5a5958!important;
}
.columnFunnel .columnFunnelContent{
  color:white;
  font-size: 14px; 
  font-weight: normal;
  position: absolute;
  top:0;
  margin: auto auto;
  width: 100%;
  margin-bottom: 10px;
  padding-top:5px;
}
.funnelPercentage{
  position:absolute;
  /*left: 160px;*/
  right:-5%;
  color:black;
  font-size: 14px!important;
  font-family: inherit;
}
.lastFunnelPercentage{
  position:absolute;
  /*left: 137px;*/
  right:10%;
  color:black;
  font-size: 14px!important;
  font-family: inherit;
}

.followupValue{
  position:absolute;
  right:-40px;
  bottom:0;
  color:black;
  font-size: 14px!important;
}
.assignedLeadsPercentage{
  position:absolute;
  right:-50px;
  bottom:0;
  color:black;
  font-size: 14px!important;
}
.assignedLeadsValue{
  position:absolute;
  right:-40px;
  bottom:0;
  color:black;
  font-size: 14px!important;
}
.spkPercentage{
  position:absolute;
  right:-50px;
  bottom:0;
  color:black;
  font-size: 14px!important;
}
.spkValue{
  position:absolute;
  right:-40px;
  bottom:0;
  color:black;
  font-size: 14px!important;
}

.minwidth150{
  min-width: 150px;
}


.place-self-flex-end {
  place-self: flex-end;
}

.enquiry-table-head{
  display: flex;
}

.enquiry-table-head i{
  line-height: inherit;
  margin: auto 0;
}

.searchWrapper{
  padding: unset!important;
  background-color: #fff;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 0px!important;
}

.searchWrapper .chip{
    margin-top: 5px;
    margin-bottom: unset!important;
    margin-left: 5px;
    margin-right: unset!important;
}
.searchWrapper .chip.singleChip{
  padding: 5px;
  margin: 5px!important;
}

.dropdown-heading{
  margin:2px;
}

.dropdown-container::selection{
  border-color:red;
}
.css-t3ipsp-control{
  box-shadow:  0 0 0 2.5px rgba(190, 157, 115)!important;
}
  .css-t3ipsp-control:hover{
  
  border-color: rgba(190, 157, 115)!important;
}
